.blog-main-container { max-width: 1144px; width: 100%; margin: 0 auto; }
.blog-main { padding: 69px 0 121px; }
.blog-item-in { border: 1px solid #96AD6E; border-radius: 10px; padding: 15px 15px 38px; position: relative; overflow: hidden; }
.blog-item-in .btn-link { position: absolute; width: 100%; left: 0; bottom: 0; height: 38px; display: flex; align-items: center; background: #ECF0EC; padding: 10px 16px; font-size: 14px; line-height: 24px; font-weight: 600; color: #4E714A; }
.blog-item-desc { min-height: 168px; }
.blog-item { margin-bottom: 32px; }
.blog-item-large .blog-item-in .blog-item-img { width: 42.5%; height: auto; flex: 0 0 auto; margin: 0; background: transparent; }
.blog-item-large .blog-item-in .blog-item-desc { width: 57.5%; flex: 0 0 auto; padding: 10px 5px 0 20px; }
.blog-item-large .blog-item-in { display: flex; align-items: center; padding: 15px; }
.get-latest-info-in { background: #ECF0EC; border-radius: 10px; padding: 32px 32px 25px; /* height: 100%; */
min-height: 328px; }
.get-latest-info-in h3 { font-family: "Kalnia", serif; color: #1D1D1D; font-size: 20px; line-height: 24px; font-weight: 400; padding: 13px 0 3px; }
.get-latest-info-in p { font-size: 14px; line-height: 21px; padding-bottom: 16px; }
.blog-item-desc .blog-item-date { color: #92949A; font-size: 10px; font-weight: 500; letter-spacing: 1px; text-transform: uppercase; line-height: 18px; padding-bottom: 12px;/* font-family: "Poppins", sans-serif; */ }
.blog-item-desc .blog-item-title { font-size: 16px; color: #282834; line-height: 22px; font-family: "Kalnia", serif; padding-bottom: 7px; }
.blog-item-large .blog-item-in .blog-item-para { padding-bottom: 10px; }
.blog-item-desc .blog-item-para { color: #333333; font-size: 14px; line-height: 22px; font-weight: 400; padding-bottom: 19px; }
.blog-sortbywrapper { background: #ECF0EC; padding: 7px 10px; position: sticky; 
  top: 116px;
  transition: all 0.5s ease-in-out;
  z-index: 11;}
  .blog-sortbyright ul li{position: relative;}
  .tooltip{
    position: absolute;
    right: 0;
    top: 50px;
    background: #97ad6e;
    padding: 0px 20px;
    border-radius: 9px;
    color: #fff;
    display: block;    width: 160px;
  }
  .tooltip > p {
    font-size: 14px;
    padding: 10px 20px;
    color: #fff;text-align: center;
  }

  .blog-item-alert{
    display: flex;
    align-items: center;
    padding: 15px;    border: 1px solid #96AD6E;
    border-radius: 10px;min-height: 327px;justify-content: center;
  }
.blog-sortby-gird {    /* max-width: 1144px; */
  width: 100%;
  padding: 0 58px;
  display: flex;
  align-items: center;}
.blog-sortbyleft { flex: 1 1 auto; }
.blog-sortbyright { flex: 0 0 auto; }
.blog-sortbyleft p { font-size: 14px; font-weight: 300; line-height: normal; color: #1D1D1D; }
.blog-sortbyleft p span{font-weight: 500;}
.blog-sortbyright ul li:last-child { padding-right: 0 !important; }
.blog-sortbyright ul li { display: inline-block; padding-right: 36px; }
.blog-sortbyright ul li a , .blog-sortbyright ul li span { color: #1D1D1D; font-size: 14px; font-weight: 400; line-height: normal; transition: all ease-in-out 0.3s; }
.blog-sortbyright ul li a:hover, .blog-sortbyright ul li span:hover { color: #4e714a; text-decoration: underline; line-height: normal; }

.blog-sortbyright ul li a.active, 
.blog-sortbyright ul li span:focus,
.blog-sortbyright ul li span.active { color: #4e714a; font-weight: 500; text-decoration: underline; line-height: normal; }
.blog-item-large .blog-item-in .blog-item-img img { width: 296px; height: 296px; max-width: 100%; }
.blog-item-img img { width: 162px; height: 162px; border-radius: 10px; object-fit: cover; }
.blog-item-large .blog-item-in .blog-item-desc .btn-link { font-size: 16px; line-height: 24px; font-weight: 600; color: #4E714A; position: static; background: transparent; padding: 0; height: auto; }
.blog-item-in .blog-item-img { margin-bottom: 11px; }
.blog-item-large .blog-item-in .blog-item-date { font-size: 12px; letter-spacing: 1.2px; }
.form-floating { position: relative; }
.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) { padding-top: 21px;  padding-bottom: .625rem; outline: none;
}
.border-0 { border: 0 !important; padding: 12px 25px; }
.form-floating>.form-control, .form-floating>.form-select { height: 48px; line-height: 1.25; }
.form-control { display: block; width: 100%; padding: 15px 17px; font-size: 14px; font-weight: 400; line-height: 1.5; color: #1D1D1D; background-color: #fff; background-clip: padding-box; border: 1px solid #4E714A; appearance: none; border-radius: 10px; font-family: "Poppins", sans-serif; transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; }
.form-floating>label {     color: #333333;
    opacity: 1 !important;
    font-size: 14px;
    line-height: 15px;
    font-weight: 400;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 16px;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out; } 
.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label { opacity: .65;  transform: scale(.85) translateY(-.5rem) translateX(.15rem); color: #595A5C; font-size: 10px;
}
.blogdetail-sort .blog-sortbyright ul { font-size: 0; }
.blogdetail-sort .blog-sortbyright ul li { padding-right: 10px; }
.blog-details-main { padding: 55px 0; }
.blog-details-main .blog-item-img { margin-bottom: 33px; }
.blog-details-main .blog-item-img img { width: 296px; height: 296px; }
.blog-details-main .blog-item-desc .blog-item-date { font-size: 12px; letter-spacing: 1.2px; }
/* .blog-details-main .blog-item-para p span { font-weight: 500; } */
.blog-details-main .blog-item-para p { line-height: 22px; font-size: 14px; }
.blog-details-main .blog-item-para { padding-bottom: 0px; }
.blog-details-main .blog-item-desc h3 { font-size: 14px; color: #595A5C; font-weight: 500; }
.blog-details-main .blog-item-in { padding-bottom: 15px; }
.archived-posts-gird h3 { color: #1D1D1D; font-size: 18px; line-height: 24px; font-weight: 400; padding: 0 0 18px; }
.archived-posts-gird .archived-posts-card { border: 1px solid #97AD6E; border-radius: 10px; overflow: hidden; margin-bottom: 16px; }
.archived-posts-gird .archived-posts-card h4 { font-size: 10px; letter-spacing: 1px; text-transform: uppercase; color: #92949A; font-weight: 500; padding-bottom: 0; }
.archived-posts-cont { padding: 6px 15px; }
.archived-posts-gird .archived-posts-card p { font-family: "Kalnia", serif; line-height: 22px; color: #282834; padding-bottom: 16px; min-height: 71px; }
.archived-posts-gird .archived-posts-card .btn-link { width: 100%; height: 38px; display: flex; align-items: center; background: #ECF0EC; padding: 10px 16px; font-size: 14px; line-height: 24px; font-weight: 600; color: #4E714A; }
.archived-posts-gird { padding: 0 0 0 5px; }
.form-floating>.form-control::-ms-input-placeholder { /* Edge 12-18 */
    color: transparent;
  }
  
  .form-floating>.form-control::placeholder {
    color: transparent;
  }
  .blog-item-in{height: 100%;position: relative;}
  .blog-item-para * {font-family: "Poppins", sans-serif !important;line-height: 25px !important;    color: #333 !important;}
  .blog-item-para p, .blog-item-para  > span, .blog-item-para p em , .blog-item-para p + span{    font-size: 14px !important;
    line-height: 24px !important;
    color: #333333 !important;
    padding: 10px 0 10px 0 !important;text-align: left !important;
    margin: 0 !important;
    font-weight: 400 !important;    font-family: "Poppins", sans-serif !important;    }
    .blog-item-para strong{font-weight: 500 !important;}
    .blog-item-para p a, .blog-item-para a, .blog-item-para span, .blog-item-para span > span{color: #333333 !important;font-size: 14px !important;}
    .blog-main.blog-details-main{min-height: 400px;    display: block;}

    .blog-item-para .add-dynamic-font{    font-size: 14px !important;
      color: #333 !important;
      line-height: 22px !important;font-weight: 500 !important;
      font-family: "Poppins", sans-serif !important;  
      padding-bottom: 7px !important;
  }



    @media(max-width:1079px){
      .blog-sortbyright.socialicons{    position: relative;
        width: 50%;
        text-align: right;
        height: auto;
        padding-right: 15px;}
        .blog-sortbyright.socialicons li{display: inline-block;
          width: auto;
          padding-bottom: 0;
      } 
      .blog-main-container{padding: 0 12px;}
    }
    @media (max-width:767px) {
      .blog-item-img img, .blog-details-main .blog-item-img img{width: 100%; height: 332px;}
      .blog-item-large .blog-item-in{display: block;}
      .blog-item-large .blog-item-in .blog-item-img{    width: 100%;}
      .blog-item-large .blog-item-in .blog-item-img img{    width: 100%;
        height: auto;}
      .blog-item-large .blog-item-in .blog-item-desc{    width: 100%;
        padding: 20px 0 5px;}
      .blog-main{padding: 45px 0 120px;}
      
      .get-latest-info-in{margin-bottom: 25px;}
      .shimmer-res{display: block !important;}
      .shimmer-px{padding: 0 16px !important;}
      .blog-main-container.shimmer.blog-item-in{width: 100% !important;}
      
    }

    .blog-item-para span a,  .blog-item-para a span{
      color: #4E714A !important;
      font-family: Poppins, sans-serif !important;
      line-height: 25px !important;
      font-weight: 600 !important;
    }