.homebanner-section{position: relative; display: block;}
.home-location-main{background-size: cover !important; padding: 76px 0 70px;}
.home-loc-grid{max-width: 536px; width: 100%; margin: 0 auto; text-align: center;}
.home-loc-grid .heading_24{color: #fff;}
.home-loc-grid p{color: #fff;}
.home-loc-btn .btn-outline-primary{background: transparent !important; color: #fff !important; border-color: #fff !important;}
.home-selfcare-main{padding: 65px 0 15px; position: relative;}
.home-selfcare-main .container{max-width: 1169px !important;position: relative;}
.selfcare-top-cont{width: calc(100% - 10%);}
.selfcare-viewall{position: absolute; top: 6px; right: 12px;}
.selfcare-swiper-cont{margin: 33px 0 30px;}
.selfcare-swiperslider{border: solid 1px #97AD6E; border-radius: 10px; padding: 13px 15px 0 15px; position: relative; height: 100%;}
.selfcare-inner-info{padding: 0 0 45px;}
.selfcare-swiperslider figure{position: relative; padding-bottom: 100%;}
.selfcare-swiperslider figure img{width: 100%; height: 100%; display: block; object-fit: cover; position: absolute; top: 0; left: 0; right: 0; border-radius: 5px;}
.selfcare-swiperslider h3{font-family: "Kalnia", serif; font-size: 20px; line-height: 24px; color: #1D1D1D; font-weight: 400; padding: 8px 0 0;}
.selfcare-swiperslider p{font-size: 14px; line-height: 22px;}
.swiper-autoheight, .swiper-autoheight .swiper-slide { height: 100%;}
.discover-btn{width: 100%; position: absolute; bottom: 0; left: 0; right: 0;}
.discover-btn .btn-link{font-size: 14px; line-height: 24px; color: #4E714A; font-weight: 600; display: block; background: #ECF0EC; border-radius: 0 0 10px 10px; padding: 7px 15px;    position: relative;
    z-index: 999;}

.home-offer-main{background: #F9F9FC; padding: 35px 0 15px;}
.home-offer-main .container{max-width: 1169px !important;}
.home-offer-info-col{padding: 20px 0; max-width: 578px;}
.home-offer-info-col .heading_24{padding: 0 0 20px;}
.home-offer-info-col p{padding: 0 0 8px;}
.home-offer-info-col p strong{font-weight: 500;}
.home-offer-image-cont{max-width: 502px; width: 100%; margin-left: auto; padding: 20px 0;}
.home-offer-image-cont figure{position: relative; padding-bottom: 79%;}
.home-offer-image-cont figure img{width: 100%; height: 100%; display: block; object-fit: cover; position: absolute; top: 0; left: 0; right: 0; border-radius: 10px; }
.home-offer-info-col .btn-outline-primary{margin: 25px 0 0;}
.home-offer-main .row{margin-bottom: 17px;}
.home-offer-main .row:nth-child(even){flex-direction: row-reverse;}
.home-offer-main .row:nth-child(even) .home-offer-info-col{margin-left: auto;}
.home-offer-main .row:nth-child(even) .home-offer-image-cont{margin-left: 0;}
.home-spotlight-main{padding: 70px 0 27px;position: relative;}
.home-spotlight-main .container{max-width: 1169px !important;position: relative;}

#StaffModal .fw-medium strong{font-weight: 500 !important;}
.home-spotlight-swiper{margin: 40px 0 30px;}
.spotlight-swiperslider{border: solid 1px #97AD6E; border-radius: 10px; padding: 15px 15px 0 15px; position: relative; height: 100%;}
.spotlight-inner-info{padding: 0 0 45px;}
.spotlight-swiperslider figure{position: relative;}
.spotlight-swiperslider figure img{width: 162px; max-width: 100%; height: 162px; display: block; object-fit: cover; border-radius: 5px;}
.spotlight-swiperslider h3{font-size: 10px; line-height: 18px; color: #92949A; letter-spacing: 1px; text-transform: uppercase; font-weight: 500; padding: 15px 0 0;}
.spotlight-swiperslider h4{font-family: "Kalnia", serif; font-size: 16px; line-height: 22px; color: #282834; font-weight: 400; padding: 8px 0 0;}
.spotlight-swiperslider p{font-size: 14px; line-height: 22px;}

.swiper-autoheight, .swiper-autoheight .swiper-slide{min-height: 396px;}

.modal-staff-content-load .close {
    position: absolute;
    right: 0;
    top: 0;
    background: #e2d3cb;
    border: 0;
    padding: 10px;
    border-bottom-left-radius: 10px;
}

.modal-staff-content-load  .shimmer{    display: block;
    min-height: 400px;
    margin: 30px 0 10px;
    border-radius: 13px;}

@media (min-width:768px){
.col-md-54{width: 54%;}
.col-md-46{width: 46%;}
}
@media (max-width:767px){
.home-location-main{padding: 45px 0 45px;}
.selfcare-viewall{position: static;}
.home-selfcare-main{ padding: 45px 0 35px;}
}
@media (max-width:575px){
.home-offer-main .row{flex-direction: column-reverse;}
.home-offer-main .row:nth-child(even){flex-direction: column-reverse;}
}

