.promotion-bg{background: #F9F9FC; padding: 56px 0 100px;;}
.promotion-wrapper { max-width: 1144px; width: 100%; margin: 0 auto; }
.promotion-gird { border: 1px solid #97AD6E; border-radius: 10px; overflow: hidden; background: #fff; margin-bottom: 15px;}
.promotion-gird .promotion-card { padding: 15px;min-height: 323px;}
.promotion-gird .promotion-card .promotion-img img { width: 162px; height: 162px; object-fit: cover; border-radius: 5px; }
.promotion-gird .promotion-card .promotion-img { margin-bottom: 7px; }
.promotion-gird .promotion-card h3, .promotion-modal-gird .promotion-card h3 { color: #282834; font-family: "Kalnia", serif; font-size: 16px; font-weight: 400; line-height: 22px; padding-bottom: 0; }
.promotion-gird .promotion-card h3 span { float: right; padding-right: 5px; }
.promotion-gird .promotion-card h3 span img { vertical-align: -1px; display: inline-block; }
.promotion-gird .promotion-card p, .promotion-modal-gird .promotion-card p { font-size: 14px; line-height: 22px; font-weight: 400; min-height: 147px; padding-bottom: 5px; }
.promotion-download .btn-link {border: 0; box-shadow: none; outline: 0; width: 100%; height: 38px; display: flex; align-items: center; background: #ECF0EC; padding: 10px 16px; font-size: 14px; line-height: 24px; font-weight: 600; color: #4E714A; }/* CSS Document */
.promotion-modal-gird .promotion-img img { width: 202px; height: 242px; object-fit: cover; border-radius: 5px; }
.promotion-modal-gird{    padding: 13px 16px;}
.promotion-modal-gird .promotion-img{     margin-bottom: 7px;}
.promotion-modal-gird .btn-link{      background: transparent; font-size: 16px; padding: 15px 0 0;}
.mh-auto{min-height: auto !important;}
.promotion-min {min-height: 100px;}

@media(max-width:767px){  
    .promotion-modal-gird{padding: 25px 0px 13px;}
    .promotion-gird .promotion-card{min-height: auto;}
    .promotion-gird .promotion-card .promotion-img img, .promotion-modal-gird .promotion-img img{    width: 100%; height: auto;}
}