
*{font-family: "Poppins", sans-serif;}
.shimmer { background: #F6F7F8; background-image: linear-gradient(to right, #F6F7F8 0%, #EDEEF1 20%, #F6F7F8 40%, #F6F7F8 100%); background-repeat: no-repeat; background-size: cover; display: inline-block; position: relative; animation-duration: 1s; animation-fill-mode: forwards; animation-iteration-count: infinite; animation-name: placeholderShimmer; animation-timing-function: linear; }
@keyframes placeholderShimmer {  0% {
 background-position: -468px 0;
}
 100% {
 background-position: 468px 0;
}
}
.error-fetch{    min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;}

  .text-danger{font-size: 12px;color: #dc3545;font-weight: 500;}

  
.form-control.input-error{
  border: 2px solid #dc3545;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: 20px;
}
.form-control.input-error:focus {
  border-color:#dc3545;
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}
.g-4{gap: 16px;}

.locationteam_paragh *, .description_special * {  background: inherit !important;     color: #333 !important;
  margin: 0 !important;   font-family: "Poppins", sans-serif !important;  font-size: 14px !important;
  line-height: 22px !important;}

  .description_special > p, .promotionCont  p {min-height: auto !important;}
  .description_special strong, .promotionCont  p strong{font-weight: 400 !important;}
  #StaffModal .promotion-gird .promotion-card p, .promotion-modal-gird .promotion-card p{min-height: auto !important;font-weight: 400 !important;}
  #StaffModal  p strong {font-weight: 400 !important;  font-family: "Poppins", sans-serif !important;}
 .shimmer.bg-transparent{background-image: linear-gradient(to right, #F6F7F8 0%, #EDEEF1 20%, #F6F7F8 40%, #F6F7F8 100%) !important;}
 .shimmer.min-48{min-height: 48px;border-radius: 10px;}
 .shimmer.max-348{max-width: 348px;}
 .react-datepicker-wrapper{width: 100%;}
 .popup_link{padding: 4px 3px; display: inline-block;color: #4E714A;text-decoration: underline !important;}

 .modal-staff{ opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;}
  .modal-staff.show {
    opacity: 1;
    visibility: visible;
  }
  .modal-staff-dialog {
   
    transform: scale(0.7);
    transition: transform 0.3s;
  }

  .modal-staff.show .modal-staff-dialog {
    transform: scale(1);
  }
  .bg-none {background: none;}
  .modal-overlay {position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(29, 29, 29, 0.50);}
  .blog-sortbyleft p{position: relative;z-index: 999;}
  .btn, .btn-outline-primary{    font-family: "Poppins", sans-serif;}
  .link-clr{color: #4E714A;font-weight: 600;font-size: 14px; font-family: "Poppins", sans-serif;}
  .btn-primary{border: 0;box-shadow: none;outline: 0;}
  .fancybox__container{z-index: 99999 !important;}

  /* body .uwy.userway_p5 .userway_buttons_wrapper{bottom: 80px !important;} */


  /* .fade-in-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .fade-in-section.is-visible {
    opacity: 1;
    transform: translateY(0);
  } */

  .fade-in-section {
    opacity: 0;
    transform: translateY(30px) scale(0.95);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    will-change: opacity, transform;
  }
  
  .fade-in-section.is-visible {
    opacity: 1;
    transform: translateY(0) scale(1);
  }