body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td { margin: 0; padding: 0; color: #333333; }
table { border-collapse: collapse; border-spacing: 0; }
fieldset, img { border: 0; }
[role=button] { cursor: pointer;}
button,select {text-transform: none;}
select {word-wrap: normal;}
select:disabled {opacity: 1;}
[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {display: none !important;}
button,[type=button],[type=reset],[type=submit] {-webkit-appearance: button;}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}
*,
*::before,
*::after {
  box-sizing: border-box;margin: 0;padding: 0;
}
address, caption, cite, code, dfn, th, var { font-style: normal; font-weight: normal; }
ol, ul { list-style: none; }
caption, th { text-align: left; }
h1, h2, h3, h4, h5, h6 { font-size: 100%; color: #363636; }
q:before, q:after { content: ''; }
abbr, acronym { border: 0; }
img{max-width: 100%; height: auto; display: block;}
.img-full-fill{display: block; width: 100%; height: auto; }
.img-fluid {max-width: 100%; height: auto; }
/**************************************************************************************/
a { color:#333333; text-decoration: none; outline: 0 none; outline: none; }
a:not([href]):not([class]), a:not([href]):not([class]):hover {color: inherit;text-decoration: none;}
a:hover { color: #333333; -webkit-transition: all 0.7s ease; -moz-transition: all 0.7s ease; -o-transition: all 0.7s ease; transition: all 0.7s ease;}
h1 { font-size: 32px; color:#333333; padding: 10px 0 10px 0; font-weight: 700; margin: 0; }
h2 { font-size: 27px; color:#333333; padding: 10px 0 10px 0; font-weight: 700; margin: 0; }
h3 { font-size: 18px; color: #333333; padding: 10px 0 10px 0; font-weight: 700; margin: 0; }
h4 { font-size: 16px; color:#333333; padding: 10px 0 10px 0; margin: 0; }
h5 { font-size: 14px; color: #333333; padding: 10px 0 10px 0; margin: 0; }
h6 { font-size: 12px; color:#333333; padding: 10px 0 10px 0; margin: 0; }
p { font-size: 16px; line-height: 24px; color: #333333; padding: 10px 0 10px 0; margin: 0; font-weight: 400; }
/*----------------------------------------------------------------------------------*/
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: initial;
  }
}
::selection { background: #333333 ; color: #fff !important; text-shadow: none; }
::-moz-selection { background: #333333; color: #fff !important; text-shadow: none; }
::-webkit-scrollbar { background: #333333; width: 10px}
::-webkit-scrollbar-track { box-shadow: inset 0 0 5px #101010}
::-webkit-scrollbar-thumb { background: #6d6b6b; border-radius: 5px}

/*----------------------------------------------------------------------------------*/ 
body { font-size: 14px; -webkit-font-smoothing: antialiased; overflow-x: hidden; background:#fff;  font-family: "Poppins", sans-serif; font-weight: 400; font-style: normal;} 
.wrapper {width: 100%; padding: 116px 0 0; transition: all ease-in-out .2s; 
/* max-width: 1280px;  */
 margin: 0 auto;
}
.container { max-width: 1280px !important; width: 100%; }
.btn-primary { background: #4E714A; border-radius: 10px; font-size: 16px; font-weight: 500; line-height: 22px; color: #FFFFFF !important; padding: 12px 20px; text-align: center; display: inline-block; font-family: "Poppins", sans-serif;}
.btn-primary:hover{ background: #4f754a;}
.btn-outline-primary{background: transparent; display: inline-block; border-radius: 10px; border: solid 2px #4E714A !important; font-size: 16px; font-weight: 500; line-height: 22px; color: #4E714A !important; padding: 12px 20px;}

/* ------------------ HEADER HERE -------------------*/  
.logo { display: block;  width: 100%;transition: all 0.5s ease-in-out; }
.logo h1 {padding: 0;margin: 0;}
.logo a{display: flex; align-items: center;}
.logo img{transition: all 0.5s ease-in-out;max-width: 100%; width: 40px; height: auto; display: block; margin: 0;}
.logo small img{transition: all 0.5s ease-in-out;max-width: 100%; width: 100%; height: auto; display: block;margin: 6px 0 0 4px;}

.header	{background-color:#fff; height: auto; position: fixed;top: 0; left: 0; right: 0; width: 100%; z-index:9999;padding: 20px 0 20px; transition: all 0.5s ease-in-out;} 
.headertoprgt {margin:0; transition: all 0.5s ease-in-out; }

.header.sticky { transition: all 0.5s ease-in-out; padding: 20px 0 10px;} 
.header.sticky .logo img{width: 30px;transition: all 0.5s ease-in-out; height: auto;} 
.navbar-header{display: none;}
.head-left{    width: 63%;
  display: flex;
  align-items: center;}
.head-login{width: 30%; max-width: 300px; text-align: right;margin: 0;}
.head-login ul{display: flex; align-items: center; justify-content: space-between;}
.head-login ul li{display: block;margin: 0 0 0;}
.head-login ul li.login-link a{color: #1D1D1D;font-size: 16px; line-height: 22px;}
.head-login ul li.login-link small{margin:0 5px 0 0;}
.head-login .btn-primary{width: 119px;}

/* ==================== Header ENd ==================== */
/* ==================== Navigation Start ==================== */
/* .mobilelist {display:none;}
.mobilelist .headertoprgt {display:none ;}   */
.nav-brand-parent{display: flex; max-width: 100%; width: 100%;justify-content: space-between; align-items: center; margin-left: auto; margin-right: auto; padding: 0 55px;}
.navigation{ position: relative; background-color: rgba(255, 255, 255, 0.69);} 
.navlist{    /* width: 15%; */
  max-width: 215px; display: inline-block; padding: 0 0; position: relative;transition: all 0.5s ease-in-out;}
.navbar-default {width: 60%; max-width: 667px; display: inline-block; border: none; box-shadow: none; border-radius: 0; background: none;padding: 0 40px;transition: all 0.5s ease-in-out;} 
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .active > a {background:none !important; box-shadow:none !important;}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {background:none !important; box-shadow:none !important;}
.navbar-nav{ display: flex; display: -webkit-flex; -webkit-justify-content: space-between; justify-content: space-between; width: 100%; align-items: center; max-width: 324px; flex-wrap: wrap;}
.navbar-default .navbar-nav > li{text-align:center;padding:0;display:inline-block; position:relative; vertical-align: middle;}
.navbar-default .navbar-nav > li > a{font-size:16px; font-weight: 400; display: block; line-height:22px; color:#1D1D1D;padding:2px 2px; text-shadow:none !important; text-transform: capitalize; transition:all ease-in-out 0.3s;}
.navbar-default .navbar-nav > li:hover > a{text-decoration: none;font-weight: 500;}
.navbar-default .navbar-nav > li.active > a {color:#1D1D1D;}
.navbar-default .navbar-nav > li > a:hover:after {visibility:visible; width:100%; opacity:1;} 
.navbar-default .navbar-nav > li > a:after	{content:''; display:none; width:5%; height:1px; background:#fff !important;  margin:6px auto 0; padding:0; visibility:visible; opacity:0; transition:all ease-in-out 0.3s; }
.navbar-nav > li.active > a:after {content:''; width:100%;  visibility:visible; opacity:1; } 
.navbar-default .navbar-nav li.active ul li a {color:#1D1D1D;}

.navbar-nav >	li > .droplist-parent > ul {top:100%; left:0; position:static;padding:15px 15px; background:#fff; display: flex; /*transform:translateY(25px);*/ transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s; border-radius: 10px;}

.navbar-nav >	li > .droplist-parent > ul li {display:block; float:none; text-align:left;padding: 0 0 5px;}
.navbar-nav >	li > .droplist-parent > ul li a , .navbar-nav >	li > .droplist-parent > ul li strong {font-size:12px; line-height:15px; font-weight: 400; color:#1D1D1D; padding:2px 0; display:block; text-transform: capitalize;}
.navbar-nav > li > .droplist-parent > ul li a:hover{color: #4E714A;}


.navbar-nav >	li > .droplist-parent > ul li a strong, .navbar-nav >	li > .droplist-parent > ul li strong{font-weight: 500;}
/* .navbar-nav	li ul ul {width:155px; top:0; left:155px; border-radius: 0; position:absolute; box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5) !important; display:none; background:#fff;  padding:0; }
.navbar-nav	li ul ul li {display:block; float:none; padding:0 5px; text-align:left;  line-height:21px;}
.navbar-nav	li ul ul li a {font-size:14px; line-height:18px;font-weight: 400; color:#1D1D1D;padding:8px 10px;display:block;}
.navbar-nav	li ul li:hover{background-color: transparent; color:#1D1D1D; transition:all ease-in-out 0.5s;}
.navbar-nav	li ul li a:hover{opacity: 0.8;}  */

/* .navbar-nav li span {background:url(../images/nav_arrow.svg) no-repeat center; width:25px; height:25px; position:absolute; right:0px; top:5px; display:none; cursor:pointer;position:absolute;top: 0;display:none;cursor:pointer;width: 100%;left: auto; background-position: right;transition:all ease-in-out 0.3s;}
.navbar-nav li span.open {background:url(../images/nav_arrow.svg) no-repeat center;transition:all ease-in-out 0.3s;    background-position: right;} */
.navbar-nav > li span { height:25px; position:absolute; right:0; top:12px; display:none; cursor:pointer;}
.navbar-nav > li span::after{content: '';display: flex;background:url(../images/nav_arrow.svg) no-repeat;width: 25px;height: 25px;background-position: center;background-size: 14px 8px;position: absolute;justify-content: flex-end;right: 0;}
.navbar-default .navbar-nav > li.open span::after{transform: rotate(180deg);}

.navbar-toggle .icon-bar:nth-of-type(2) { top: 1px; }
.navbar-toggle .icon-bar { position: relative; transition: all 500ms ease-in-out; }
.navbar-toggle.active .icon-bar:nth-of-type(1) { top: 6px; transform: rotate(45deg); }
.navbar-toggle.active .icon-bar:nth-of-type(2) { background-color: transparent; }
.navbar-toggle.active .icon-bar:nth-of-type(3) { top: -10px; transform: rotate(-45deg); width: 30px;}
.navbar-toggle.active .icon-bar { width: 30px; height: 2px }
.navbar-toggle .icon-bar { display: block; width: 30px; height: 2.7px; border-radius: 50px; background: #000000;/* margin-left: 7px; */ }
.navbar-toggle { float: none; display: block; margin: 0px 0 0 0px; border-color: transparent; padding: 0; border-radius: 50%; background: 0 0; position: relative; outline: 0!important; cursor: pointer;}
.navbar-toggle .icon-bar:nth-child(2) { width: 30px; margin: 6px 0; }
.navbar-toggle .icon-bar:nth-of-type(3) { top: 2px; }
.navbar-toggle .sr-only { width:45px;text-align:center;height:auto;font-size:12px;line-height:12px;clip:inherit;position:absolute;left:-31px;top:15px;transform:rotate(-90deg);-ms-transform:rotate(-90deg);-webkit-transform:rotate(-90deg);-o-transform:rotate(-90deg);-moz-transform:rotate(-90deg);text-shadow:inherit!important;color:#fff;font-weight:400; display: none;} 


.nav-items-list{width: calc(100% - 252px);position: relative;}
.navbar-nav > li > .droplist-parent > ul > li.nav-items-list{display: flex; /*width: 62%;*/ width: calc(100% - 196px);padding: 2px 20px 0 0;}
.nav-items-list > ul{padding: 0;}
.nav-items-list > ul.nav-salon{width: 50%;}
.nav-items-list > ul.nav-wellness{width: 50%;}
.nav-items-list > ul > li:first-child{padding: 0 0 12px;}
.navbar-nav > li > .droplist-parent > ul li.drop-hover-img{width: 196px;}
.drop-hover-img img{width: 100%; /*height: 100%;*/height: 196px; object-fit: cover; display: block;border-radius: 10px;}
.navbar-default .navbar-nav > li.mobile-navlink{display: none;}

/* ==================== Navigation End ==================== */
.videoWrapper{width: 100%; position: relative; height: 84vh;/*padding-bottom: 50.6%;*/}
.videoWrapper video, .videoWrapper iframe{position: absolute;top: 0;left: 0;width: 100%;height: 100%;margin: 0;padding: 0;border: 0;outline: none;cursor: pointer; object-fit: cover;}

.play-pause {width:50px;height:50px;border-radius:50px;box-shadow: 0px 0px 5px 0px rgba(1,1,1,0.15) !important;background-color: #ABC7CA !important;background-image:url(../images/mute.svg);background-repeat:no-repeat;background-position:center;background-size:cover;font-size:0px;line-height:50px;color:#ffffff;text-align:center;position:absolute;right:15px;bottom:15px;z-index:1;cursor:pointer;}
.play-pause.active{background-image:url(../images/unmute.svg);}

.inner-banner-main{position: relative;}
.inner-banner::before{content: ''; width: 100%; height: 100%; position: absolute; top: 0; left: 0; display: block; background-color: rgba(236, 240, 236, 1); z-index: -1;}
.inner-banner img{width: 100%; height: 324px;display: block; object-fit: cover; opacity: 0.1;}
.innerbanner-overlay{position: absolute; top: 0; left: 0; right: 0; width: 100%; height: 100%; display: flex; align-items: center; z-index: 2;}
.innerbanner-overlay .container{    max-width: 100% !important;
  padding: 0 68px;}
.innerbanner-overlay h2{font-family: "Kalnia", serif;color: #1D1D1D; font-size: 36px; line-height: 24px;font-weight: 400; padding: 20px 0 20px; text-transform: capitalize;}
.innerbanner-overlay p{color: #1D1D1D; font-size: 18px; line-height: 24px; font-weight: 300; padding: 10px 0 0;}

.heading_24{font-family: "Kalnia", serif; font-size: 24px; line-height: 25px; color: #333333; font-weight: 400; padding: 0 0 10px;}
.heading_36{font-family: "Kalnia", serif; font-size: 36px; line-height: 25px; color: #1D1D1D; font-weight: 400; padding: 0 0 15px;}
.gray-bg{background: #F9F9FC !important;}

footer{background: #fff; border-top: solid 1px #4E714A;}
.footer-top{padding: 30px 0 30px;}
.footer-top .container{max-width: 1169px !important;}
.footer-heading{font-size: 12px; line-height: 24px; color: #92949A; letter-spacing: 0.6px; text-transform: uppercase; font-weight: 400;}
.footer-nav ul li{margin: 10px 0 13px;}
.footer-nav ul li a{font-size: 16px; line-height: 24px; font-weight: 400; color: #1D1D1D;}
.footer-bottom{background: #1D1D1D; text-align: center;}
.footer-bottom p{font-family: "DM Sans", sans-serif;font-size: 12px; line-height: 18px; color: #F9F9FC; padding: 13px 0;}
.footer-bottom p a{color: #F9F9FC;}
.footer-social{text-align: left; margin: 0 0 0 auto;}
.footer-social ul{display: inline-flex; margin: 13px 0 0;}
.footer-social ul li{margin: 0 5px 0 0;}
.footer-loclist{padding: 0 40px 0 0;}
.footer-loclist ul{border-right: solid 1px rgba(146, 148, 154, 0.25);position: relative;}


.modal-staff.show { display: block; left: 0; transition: all .3s ease-in-out;     z-index: 99999;}
.modal-staff { -webkit-backdrop-filter: blur(3px); backdrop-filter: blur(3px); background-color: rgba(29,29,29,0.50); display: none; height: 100%; left: -100%; position: fixed; top: 0; transition: all .3s ease-in-out; width: 100%; z-index: 999;    overflow-x: hidden;
  overflow-y: auto; }
.modal-staff-dialog { margin: 0 auto; max-width: 464px; position: relative; width: 100%;   display: flex; align-items: center; justify-self: center;    position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important; }
.modal-staff-content { background: #fff; position: relative; width: 100%; border-radius: 10px; min-height: 200px; padding: 15px;}
.modal-staff-content .close { position: absolute; right: 16px; top: 14px; background: transparent; border: 0; }
.check-your-inbox-gird { max-width: 340px; padding: 0 10px; width: 100%; margin: 0 auto; text-align: center; min-height: 512px; display: flex; align-items: center; justify-content: center; flex-direction: column; }
.check-your-inbox-gird h4 { font-family: "Kalnia", serif; font-size: 18px; font-weight: 400; color: #121212; padding-bottom: 0; }
.check-your-inbox-gird p { font-family: "DM Sans", sans-serif; font-size: 14px; line-height: 22px; font-weight: 400; padding: 7px 0 21px; }
.check-your-icon img { display: inline-block; }
.check-your-icon { padding-bottom: 16px; }
body.modal-open{    overflow: hidden; padding-right: 17px;}
.font-weight-normal { font-weight: 400; }

.api-error{    text-align: center;
  border: 2px solid #dc3545;
  padding: 40px 0;
  border-radius: 10px;
  font-size: 20px;
  color: #dc3545;    text-transform: uppercase;

}
.promotion-gird .promotion-card{position: relative;}
.spaPrice{    position: absolute;
  right: 10px;
  top: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #1D1D1D;}
.spaGird .promotion-gird{margin-bottom: 30px;}
.spaGird{    max-width: 1144px;
  width: 100%;
  margin: 0 auto;}
  .spaGird h2{    font-family: "Kalnia", serif;
    color: #1D1D1D;
    font-size: 36px;
    line-height: 24px;
    font-weight: 400;
    padding: 20px 0 20px;
    text-transform: capitalize;}
    .spaGird p{    font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      padding: 5px 0 45px;}
      .spaGird p span{font-style: italic;}
      .max-1144{max-width: 1144px !important;}
.spapackageBanner{ position: relative;   max-width: 1144px; cursor: pointer;
  width: 100%;
  margin: 60px auto 0; background: url(/public/assets/images/spaPackageBanner.png) no-repeat; border-radius: 12px;
  padding: 11px 30px;     overflow: hidden;
  background-size: cover;}
  .spapackageBanner:before{content: ''; position: absolute; width: 100%; height: 100%; left: 0; right: 0; top: 0; bottom: 0; background: rgba(78,113,74,0.80);}
  .spapackageBanner h4{    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #fff; padding-bottom: 0;}
    .spapackageContent{z-index: 1; position: relative;}
    .spapackageBanner p{    font-size: 16px;
      color: #fff;
      font-weight: 400;
      line-height: 20px;}
      .spapackageContent p span{font-style: italic;}
.home-offer-info-col h3{    font-family: Kalnia, serif;
  font-weight: 400;font-size: 24px;
  line-height: 25px;}

  .iframe-theme {padding: 80px 0;    background: #fff;}

  .iframe-theme .iframe-max-theme{
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }
  .iframe-container{
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 130%;
  }
  .iframe-container iframe{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;border: 0;padding: 10px;border: 2px solid #4E714A;border-radius: 10px;
  }
 .spaGirdPosition{top:-150px; position: relative;}


  .home-offer-content{background: url('../images/home-offer-img.png') no-repeat center;     padding: 20px !important; width: 196px;height: 196px;background-size: cover;}
  .home-offer-content h3{font-size: 16px;font-weight: 400;line-height: 20px;color: #fff;margin-bottom: 8px; padding-bottom: 0;   font-family: "Kalnia", serif;}
  .home-offer-content p {font-size: 10px;line-height: 14px;color: #fff;}
  .iframe-max-theme .home-offer-content{width: 100%;border-radius: 10px;height: auto;margin-bottom: 30px;}
  .iframe-max-theme .home-offer-content h3{font-size: 18px;margin-bottom: 10px;}
  .iframe-max-theme .home-offer-content p{font-size: 15px;line-height: 22px;padding: 0 0 8px;}
  .home-offer-content .max-800{max-width: 700px;width: 100%;}
  .note-offer{padding: 30px 0 0;}
  .note-offer p {font-size: 14px;}
@media (min-width: 1080px) { 
  /* .navbar-nav >li:hover ul {display:block;visibility:visible;opacity:1;z-index:1;transform: translateY(0%);transition-delay: 0s, 0s, 0.3s;} */
  /* .navbar-nav > li > ul > ul {display:none !important;} */
  /* .navbar-nav > li > ul > li:hover ul{display:block !important;} */
  /* .navbar-nav > li > ul {  visibility:hidden;opacity:0;  display: flex !important; } */
  .droplist-parent{position: absolute; padding: 58px 0 0; width: 100%; right: 0; display: none; min-width: 585px; top: 20px; left: 0;}
  .navbar-nav > li:hover .droplist-parent { display: block;}

}

@media (max-width:1079px){
  .head-left{width: 100%; display: block;}
  .wrapper{padding:100px 0 0;}
  .logo img {width: 30px;}
  .header.sticky .logo img{width: 75px;}
  /* .logo_pos   {display:none !important;} */
  .navbar-header {display: block; padding: 0 0; margin: 0;} 
  .header.sticky .navlist{padding: 5px 0px;}
  .header.sticky{padding: 5px 0 5px;}
  .header{padding: 27px 0 27px;}
  .nav-brand-parent{display: block; padding: 0;}
  
  /* .headertoprgt {display:none;} 
  .mobilelist .headertoprgt {display:block;}  */
  .mobilelist {display:block;width: 60%;margin: auto;} 
  .collapse.navbar-collapse {display:none;}
  .navbar-default .navbar-collapse.show{ display: block !important;  transition: all ease-in-out 0.2s;  -o-transition: all ease-in-out 0.2s;
  -webkit-transition: all ease-in-out 0.2s; -ms-transition: all ease-in-out 0.2s;}
  .navbar-collapse { width: 100%; max-width: 100%; height: calc(100% - 100px); top: 100px; position: fixed; left: -100%; display: block !important; transition: all ease-in-out 0.2s; -o-transition: all ease-in-out 0.2s; -webkit-transition: all ease-in-out 0.2s; -ms-transition: all ease-in-out 0.2s; background: #fff; /* transform: translateX(-100%); */ border-top:1px solid #49365A; overflow-y: auto; overflow-x: hidden; padding: 20px 15px; z-index: 9;}
  .navbar-collapse.show { left:0; transform: translateX(0%);} 
  .navbar-default .navbar-collapse{border:none; box-shadow:none;}  
  .header.sticky .navbar-collapse{top: 100px;}  

  .navbar{display: block;}  
  /* .navbar-collapse {width: 560px;max-width: 100%;height: calc(100% - 100px);top: 100px;position: fixed;left: -110%;transition: all ease-in-out .2s;-o-transition: all ease-in-out .2s;-webkit-transition: all ease-in-out .2s;-ms-transition: all ease-in-out .2s;background: #333333;border-top: 1px solid #49365a;overflow-y: auto;overflow-x: hidden;z-index: 99;}
 */
 .navbar-collapse.active { left: 0; transform: translateX(0);transition: all ease-in-out .2s; -o-transition: all ease-in-out .2s; -webkit-transition: all ease-in-out .2s; -ms-transition: all ease-in-out .2s;}
  .navbar-default .navbar-collapse { border: none; box-shadow: none; }
  .navbar-nav {width:100%;max-width: 100%;margin:0 0 0;}
  .navbar-nav > li{width:100%;} 
  .navbar-nav > li > a{padding:5px 3px !important;}
  .navbar-nav li span {display:block; width: 100%;}
  .navbar-nav >	li .droplist-parent > ul{ position:static;width:100% !important;padding:5px 0;visibility:visible;opacity:1;transform:none!important;   transition:none!important;display:none;}
  .navlist {padding:0;position:relative;z-index:9;display: flex;width: 100%;align-items: center;justify-content: space-between;-webkit-align-items: center; max-width: 100%;
  -webkit-justify-content: space-between; display: -webkit-flex;} 
  .navbar-default .navbar-nav > li > a {text-align:left; display: block; font-size: 18px; line-height: 30px;} 
  .navbar-nav > li > .droplist-parent > ul li a{font-size: 15px; line-height: 24px;}
  .navbar-default .navbar-nav > li { padding: 5px 0; text-align:left;}
  .navbar-default .navbar-nav > li > a:after {display:none;}
  .navbar-nav > li > .droplist-parent > ul li a strong, .navbar-nav > li > .droplist-parent > ul li strong{font-size: 17px; line-height: 25px;}


  .droplist-parent{display: none; padding: 0; position: static;width: 100% !important;min-width: 100%;padding: 0 0;visibility: visible;opacity: 1;transform: none!important;transition: none!important;}
  .navbar-nav >	li .droplist-parent > ul {display:flex;position: static;width: 100% !important;min-width: 100%;padding: 0 0;visibility: visible;opacity: 1;transform: none!important;transition: none!important;outline: none !important;}
  .navbar-default .navbar-nav > li{margin: 0;}
  .navbar-default .navbar-nav > li.open .droplist-parent{display: block;}
  .navbar-nav > li > .droplist-parent > ul li.drop-hover-img{display: none;}
  .navbar-nav > li > .droplist-parent > ul > li.nav-items-list{width: 100%; flex-flow: column;padding: 2px 0 5px 0;}
  .nav-items-list > ul.nav-salon, .nav-items-list > ul.nav-wellness{width: 100%; padding: 15px 20px 0 20px;}

  .logo h1{max-width: 200px;}
  .head-login {width: 130px;margin: 0;position: absolute;top: 0;left: auto;right: 60px;z-index: 1; height: 100%; display: flex; align-items: center; justify-content: flex-end; z-index: 10;}
  .head-login ul {justify-content: flex-end;}
  .head-login ul li.login-link{display: none;}
  .head-login ul li{display: inline-block;padding: 0;margin: 0;}
  .head-login .btn-primary{width: 100px; max-width: 100%; font-size: 12px; line-height: 21px; padding: 10px 20px;}
  .navbar-default .navbar-nav > li.mobile-navlink{display: block;}

  .logo img, .logo small img{    width: 130px !important;}
}

@media (max-width:767px){
  .spaGird-section .promotion-img{    position: relative;
    padding-bottom: 100%;}
    .spaGird-section .promotion-img img{      position: absolute;
      width: 100% !important;
      height: 100% !important;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;}
  .spaPrice{    right: 2px; top: 8px;}
  .promotionCont{position: relative;}
  .check-your-inbox-gird h4{    font-size: 24px;
    line-height: 40px;}
  .check-your-inbox-gird p{    font-size: 16px;}
  .check-your-inbox-gird p br{display: none;}
  .modal-staff{padding: 0 15px;}
  .innerbanner-overlay .container{padding: 0 12px;}
  .footer-top{padding: 30px 0 30px 15px;}
  .navbar{float:none;display: block;}  
  .custom-control-inline{display: flex;}    
  .footer-loclist ul{border-right: none; padding: 0 0 30px;}
  .footer-loclist ul::after{content: ''; width: 65%; margin: 0 auto; height: 1px; border-bottom: solid 1px rgba(146, 148, 154, 0.25); display: block; position: absolute; left: 0; right: 0; bottom: 0;}
  .footer-nav{margin: 0 0 30px; padding: 0;}

  .inner-banner img{height: 340px;}
  .innerbanner-overlay h2{font-size: 31px; line-height: normal;padding: 10px 0 10px;}
  .innerbanner-overlay p{font-size: 17px;}
  .videoWrapper{height: 55vh;}
  .heading_36{font-size: 31px; line-height: normal;}

}

