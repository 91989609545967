.careerWrapper { max-width: 1144px; width: 100%; margin: 56px auto 31px; }
.careerVideo { border: 1px solid #97AD6E; border-radius: 10px; position: relative; padding: 16px; }
.careerVideo .videoWrapper{height: auto;padding-bottom: 55%;}
.career_job { max-width: 360px; width: 100%; margin: 0 0 0 auto; background: #ECF0EC; border-radius: 10px; min-height: 328px; display: flex; align-items: center; }
.career_job_content { padding: 0px 27px 10px; }
.career_job_content h3 { font-family: "Kalnia", serif; font-size: 20px; line-height: 24px; color: #1D1D1D; padding-bottom: 7px; font-weight: 400; }
.career_job_content p { font-size: 14px; line-height: 21px; color: #1D1D1D; padding-bottom: 37px; }
.career-productsection { max-width: 1144px; width: 100%; margin: 0 auto 125px; }
.careerImageBox { max-width: 368px; width: 100%; margin: 0 auto; position: relative; padding-bottom: 135%; }
.careerImageBox img { position: absolute; width: 100%; height: 100%; left: 0; right: 0; top: 0; bottom: 0; object-fit: cover; border-radius: 10px; }
@media(max-width:1079px) and (min-width:768px){
    .career_job{height: 100%; min-height: auto;}
}
@media(max-width:767px){
    .career_job{margin: 0 auto;}
    .careerImageBox{margin-bottom: 15px;}
    .careerWrapper .col-md-8{padding: 0;}
    .careerWrapper .careerVideo{    border: 0; padding: 0; margin-bottom: 15px;}
}
