.levels_wrapper{    max-width: 824px;
    width: 100%;
    margin: 47px auto 127px;}
    .levels_wrapper h3{    font-weight: 600;
        color: #1D1D1D;
        line-height: 24px; padding-bottom: 3px;}
        .levels_wrapper h4{    font-weight: 500;
            color: #1D1D1D;
            padding-top: 21px;
            padding-bottom: 0px;}
        .levels_wrapper p{    line-height: 22px;}
        .levels_wrapper p span{font-weight: 500;}
        .careFunctionWrapper ul{    padding: 15px 0 12px;}
        .careFunctionWrapper ul li{    font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: #1D1D1D;}
            .careFunctionWrapper .lightGray, .careFunctionWrapper .lightGray li{    color: #595A5C;}