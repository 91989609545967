.location_wrapper { max-width: 1144px; width: 100%; margin: 0 auto; padding: 44px 0; }
.location-heaing h2 { font-family: "Kalnia", serif; font-size: 36px; font-weight: 400; color: #1D1D1D; line-height: normal; }
.location-heaing p { font-size: 18px; font-weight: 300; line-height: 24px; color: #1D1D1D; padding-bottom: 35px; }
.location-column-sec > h3 { font-weight: 300; color: #1D1D1D; padding-bottom: 16px; }
.location-column-sec > h3 span { font-weight: 500; }
.location_gird {background: #fff; border: 1px solid #97AD6E; min-height: 396px; border-radius: 10px; padding: 15px; max-width: 272px; width: 100%; margin: 0 0 20px; }
.location_gird .location_img { position: relative; display: block; padding-bottom: 100%; }
.location_gird .location_img img { position: absolute; width: 100%; height: 100%; left: 0; right: 0; top: 0; bottom: 0; object-fit: cover; border-radius: 10px; }
.location_gird .location_content h4, .location_gird .location_content h4 a { font-family: "Kalnia", serif; font-size: 20px; color: #1D1D1D; line-height: 24px; font-weight: 400; padding-bottom: 0; }
.location_gird .location_content h4 span { float: right; }
.location_gird .location_content h4 span img { display: inline-block; }
.location_gird .location_content p , .location_gird .location_content p  > a{    color: #333333;font-weight: 400; font-size: 14px; line-height: 22px; min-height: 70px; }
.location_gird .location_content a { font-weight: 600; color: #4E714A; font-size: 14px; line-height: 24px; }
.location-column-sec { padding-bottom: 21px; }
.swiper-pagination-bullet { background: rgba(255,255,255,0.50) !important; opacity: 1 !important; width: 6px !important; height: 6px !important; margin-right: 0px !important; }
.swiper-pagination-bullet.swiper-pagination-bullet-active { background: #fff !important; }
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal { bottom: 0 !important; }
.location_team_wrapper .location_gird .location_img img { border-radius: 5px !important; filter: grayscale(100%);}
.location_team_wrapper .location_gird { padding: 0; overflow: hidden; }
.location_team_wrapper .team-bth-padd { padding: 15px 15px 0; }
.location-fixed { background: #4E714A; display: flex; align-items: center; justify-content: space-between; position: fixed; left: 0; bottom: 0; width: 100%; z-index: 11; padding: 11px 42px 10px 34px; }
.location-fixed .location-fx-left { flex: 1 1 auto; }
.location-fixed .location-fx-left h3 { font-family: "Kalnia", serif; color: #fff; padding: 0; font-size: 20px; line-height: 27px; font-weight: 400; }
.location-fixed .location-fx-left p { padding: 0; font-size: 14px; line-height: 24px; color: #fff; }
.location-fixed .location-fx-right { flex: 0 0 auto; }
.btn-white { background: #FFFFFF; border-radius: 10px; font-size: 16px; color: #4E714A; font-weight: 500; line-height: 25px; display: block; padding: 11px 20px; }
.blog-sortbyright ul li a.active { font-weight: 500; color: #4E714A; text-decoration: underline !important; }
.location_team_wrapper .location_gird { cursor: pointer; }
.locationteam_paragh { height: 426px; overflow-y: auto; }
.locationteam_paragh p { font-size: 14px; line-height: 22px; }
.locationteam_modal h3 { font-family: "Kalnia", serif; font-weight: 400; font-size: 16px; line-height: 22px; color: #282834; }
.locationteam_modal h3 span { display: inline-block; padding-left: 7px; vertical-align: -1px; }
.locationteam_paragh::selection { background: #fff; color: #fff !important; text-shadow: none; }
.loader-max{max-width: 34px;width: 100%;margin: 0 auto;}
 .locationteam_paragh::-moz-selection {
background: #fff;
color: #fff !important;
text-shadow: none;
}
.blog-sortbyleft p{
    display: flex;
    align-items: center;
    gap: 6px;
}
.blog-sortbyleft p a {
    font-size: 14px;
    font-weight: 300;
    line-height: normal;    display: inline-flex;
    align-items: center;
    color: #1D1D1D;
}
.svg-ic-style{          padding-right: 4px;
    width: 13px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    line-height: 10px;
}
.location_gird:hover { border: 1.5px solid #4E714A;}
.text-cap{text-transform: capitalize;}
.locationcontactmap-img iframe{width: 100%;}
.text-capitalize{text-transform: capitalize;}
.location_gird .location_content p, .location_gird .location_content p > a{text-transform: capitalize;}

 .locationteam_paragh::-webkit-scrollbar {
background: #fff;
width: 5px;
height: 5px;
position: relative;
transform: translateX(10px);
}
 .locationteam_paragh::-webkit-scrollbar-track {
box-shadow: none;
border-radius: 3px;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
-ms-border-radius: 3px;
-o-border-radius: 5px;
background-color: #fff !important;
}
 .locationteam_paragh::-webkit-scrollbar-thumb {
background: rgba(146,146,146,0.25);
border-radius: 5px;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
-ms-border-radius: 5px;
-o-border-radius: 5px;
}
.locationReview_know { min-height: 360px; }
.locationReview_know .career_job_content { padding: 10px 40px 10px; }
.locationReview_know .career_job_content h3 { padding-bottom: 4px; }
.review_wrapper > h3 { color: #1D1D1D; font-weight: 400; line-height: 24px; padding-bottom: 19px; }
.review_gird .review_list { border: 1px solid #97AD6E; border-radius: 10px; padding: 4px 15px 14px; margin-bottom: 17px;background: #fff; }
.review_gird .review_list h4 { font-size: 14px; line-height: 22px; font-weight: 500; color: #1D1D1D; }
.review_gird .review_list .review_star_sec { display: flex; align-items: center; justify-content: space-between; }
.review_gird .review_list .review_star_sec small { font-size: 12px; font-weight: 400; color: #333333; line-height: 22px; display: inline-block; }
.review_gird .review_list .review_star_left span { display: inline-block; padding-right: 0px; }
.review_star_left { display: inline-flex; }
.review_gird .review_list p { font-size: 14px; line-height: 22px; padding: 6px 0 0px; }
.review_gird .review_list ul { margin: 10px 0 0; padding-left: 14px; position: relative; }
.review_gird .review_list ul:before { content: ''; position: absolute; left: 0; top: 0; height: 100%; width: 4px; background: rgba(151,173,110,0.50); border-radius: 5px; }
.review_gird .review_list ul li { font-size: 12px; line-height: 22px; color: #333333; font-weight: 400; }
.review_gird .review_list ul li span { font-weight: 500; }
.review_gird .review_list h5 { font-size: 12px; line-height: 22px; font-weight: 300; color: #1D1D1D; }
.review_gird .review_list h5 span { display: inline-block; vertical-align: middle; }
.review_gird .review_list h5 span img { width: auto; }
.come_visit_wrapper { background: rgba(78,113,74,0.10); padding: 63px 0; }
.come_visit_gird { max-width: 480px; width: 100%; margin: 0 auto; text-align: center; }
.come_visit_gird h2 { font-size: 16px; color: #92949A; font-weight: 500; letter-spacing: 1.6px; line-height: 26px; text-transform: uppercase; padding-bottom: 29px; }
.come_visit_gird h3 { font-family: "Kalnia", serif; font-weight: 400; color: #121212; font-size: 24px; line-height: normal; }
.come_visit_gird p { line-height: 22px; font-family: "DM Sans", sans-serif; padding: 0 0 39px; }
.come_visit_icon { display: inline-block; padding: 25px 0 10px; }
.what_we_offer_wrapper { max-width: 1144px; width: 100%; margin: 54px auto; }
.what_we_offer_wrapper h3 { font-size: 24px; line-height: normal; font-weight: 400; font-family: "Kalnia", serif; }
.what_we_offer_wrapper p { padding-bottom: 42px; }
.link{cursor: pointer;color: #4e714a;font-weight: 600;display: inline-flex;}
.show_all_photos { position: absolute; width: 155.61px; background: #fff; border-radius: 10px; height: 41px; display: flex; align-items: center; justify-content: center; right: 12px; bottom: 10px; }
.show_all_photos a {    padding: 10px 16px; font-size: 12px; font-weight: 500; color: #1D1D1D; line-height: normal; }
.show_all_photos a span { padding-right: 5px; display: inline-block; vertical-align: -1px; }
.locationcontactmap-sec { max-width: 1144px; width: 100%; margin: 54px auto; }
.locationcontactmap-img { max-width: 752px; width: 100%; margin: 0; position: relative;}
.locationcontactmap-img img { width: 100%; }
.locationcontactmap-right { border: 1px solid #97AD6E; border-radius: 10px; padding: 15px; height: 100%; }
.locationcontactmap-cont > img { border-radius: 5px; height: 160px; object-fit: cover; width: 100%; }
.locationcontactmap-cont h3 { font-family: "Kalnia", serif; font-size: 20px; font-weight: 400; color: #1D1D1D; padding:3px 0 0px;}
.locationcontactmap-cont h3 span{float: right;}
.locationcontactmap-cont h3 span img{display: inline-block;}
.locationcontactmap-cont h4 { font-weight: 600; font-size: 14px; line-height: 24px; padding: 3px 0 0; }
.locationcontactmap-cont p { font-size: 14px; color: #1D1D1D; font-weight: 400; line-height: 24px; padding: 6px 0 6px;}
.locationcontactmap-cont ul li { font-size: 14px; color: #1D1D1D; font-weight: 400; line-height: 24px; }
.location_bg{background: #F9F9FC;}
.blog-sortbyright .blog-toggle{display: none;}
.review_bg, .locationpromotion_bg{background: #F9F9FC;} 
.promotion-min{min-height: 110px;}

.location_gird{cursor: pointer;}
.promotion-gird .promotion-card p, .promotion-modal-gird .promotion-card p{padding-bottom: 0;}
.blog-sortbyleft p{width: 85%;}
.locationcontactmap-img{padding-top: 56%;height: 100%;}
.locationcontactmap-img > iframe {position: absolute;left: 0;top: 0;width: 100%;height: 100%;object-position: center;object-fit: cover;}

 .location_team_wrapper-page .location_gird .location_img img {
    border-radius: 5px !important;
    filter: none !important;
}
.team-info-title h3{font-weight: 400;}
.promotion-modal-gird .obj-contain img {object-fit: contain;}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
   
    top: 0;
    bottom: auto;
    margin: 0;
    right: 0;
    width: 15%;
    opacity: 0;height: 100%;
}

.location-fixed .location-fx-left{ padding-left: 35px;}

.swiper-button-prev, .swiper-rtl .swiper-button-next{
   
    top: 0;
    left: 0;
    margin: 0;
    right: auto;
    width: 15%;
    opacity: 0;  height: 100%;
}

.textcapitalization{text-transform: capitalize;}
@media(max-width:1079px){
    .blog-sortbywrapper{top: 100px;}
}
@media(max-width:1079px) and (min-width:768px){
    .locationReview_know{height: auto;}
    .blog-sortby-gird{padding: 0;}
}

@media(max-width:767px){ 
    .our-storyImg{margin-bottom: 10px;}
    .come_visit_gird{padding: 0 15px;}
    .locationcontactmap-img{margin-bottom: 15px;}
    .location-fixed .location-fx-left h3{font-size: 16px; line-height: 22px;}
    .blog-sortbyright .blog-toggle.active img{transform: rotate(-180deg);}
    .blog-sortbyright .blog-toggle img{ transition: transform .2s ease-in-out; transform: rotate(0deg);}
    .blog-sortbyright .blog-toggle{display: block;position: absolute; right: 15px; top: 40%; border: 0; cursor: pointer;}
    .blog-sortbywrapper{padding: 7px 0px; top: 100px;}
    .blog-sortby-gird{padding: 0;}
    .blog-sortbyleft{width: 100%;
        padding-left: 15px;}
        .shimmer-main.location_wrapper{padding: 10px 15px;}
    .blog-sortbyright{          z-index: 1111;           position: absolute;
        width: 100%;
        display: inline-block;
        height: 54px;
        right: 0;}
        .blog-sortbyleft p {
            width: 85%;
            position: relative;
            z-index: 111111;
        }

    .blog-sortbyright ul li{padding-bottom: 15px; padding-right: 0; width: 100%;} 
    .blog-sortbyright .blog-menu{    position: absolute; 
        width: 100%;
        left: 0;
        background: #ECF0EC;
        border-top: 1px solid #4E714A;
        padding: 20px; top: 55px; display: none; transition: transform .2s ease-in-out;}
        .blog-sortbyright .blog-menu.open{display: block; transition: transform .2s ease-in-out;text-align: right
            ;}
        .come_visit_gird p br{display: none;}
}


@media(max-width:575px){
    .our-story .col-12.px2{padding: 0 5px !important;}
    .locationReview_know{max-width: 100%; margin-top: 40px;}
    .location-fixed{display: block; padding: 11px 15px 10px;}
    .location-fixed .btn-white{display: inline-block; margin-top: 2px;}
    .location-heaing h2{font-size: 31px;}
    .location-heaing p{font-size: 17px;}
    .location_gird{max-width: 100%;}
    .location-fixed .location-fx-left{padding-left: 0;}
    .location-fixed .location-fx-right{text-align: right;}
}