.our-story { max-width: 1168px; width: 100%; margin: 45px auto 35px; padding: 0 12px; }
.our-story h3 { font-family: "Kalnia", serif; font-size: 24px; font-weight: 400; }
.our-story > .row { margin-top: 51px; }
.our-storyImg { position: relative; padding-bottom: 74%; margin-bottom: 21px; cursor: pointer; }
.our-storyImg img { position: absolute; width: 100%; height: 100%; left: 0; right: 0; top: 0; bottom: 0; object-fit: cover; border-radius: 10px; }
.px3 { padding: 0 16px 0 19px; }
.px2 { padding: 0 7px; }
.tab-content>.tab-pane { display: none; }
.tab-content>.active { display: block; }
.fade { transition: opacity .15s linear; }
.fade:not(.show) { opacity: 0; }
.our_four_corner_bg { background: #ECF0EC; padding: 83px 0 79px; }
.our_four_corner_bg h3 { text-align: center; font-size: 16px; letter-spacing: 1.6px; color: #92949A; font-weight: 500; text-transform: uppercase; line-height: normal; padding-bottom: 39px; }
.our_four_gird { max-width: 730px; width: 100%; margin: 0 auto; }
.our_four_gird .nav-tabs { text-align: center; padding-bottom: 35px; }
.our_four_gird .nav-tabs li { display: inline-block; padding: 0 40px; position: relative; }
.our_four_gird .nav-tabs li:not(:first-child):after { content: ""; position: absolute; left: -3px; top: 11px; width: 8px; height: 8px; border-radius: 100%; background: rgba(151,173,110,0.50); }
.our_four_gird .nav-tabs li a.active { color: #4E714A; }
.our_four_gird .nav-tabs li a { color: #1D1D1D; font-size: 24px; font-family: "Kalnia", serif; line-height: normal; position: relative; }
.our_four_gird .nav-tabs li a.active:after { transition: all 0.3s ease-in-out; opacity: 1; }
.our_four_gird .nav-tabs li a:after { content: ''; position: absolute; width: 50%; left: 50%; bottom: 0; height: 1px; background: #97AD6E; transform: translate(-50%, 1px); transition: all 0.3s ease-in-out; opacity: 0; }
.our_four_gird .our_four_cornerContent { text-align: center; max-width: 520px; width: 100%; margin: 0 auto; }
.our_four_gird .our_four_cornerContent img { width: 34px; height: 34px; object-fit: contain; display: inline-block; }
.our_four_gird .our_four_cornerContent p { padding: 6px 0 10px; }
.our_four_gird .our_four_cornerContent p span { font-weight: 600; }
.about-community-wrapper { max-width: 1144px; width: 100%; margin: 58px auto; }
.about-community-wrapper .row { align-items: center; margin-bottom: 54px; }
.about-community-content h3 { font-family: "Kalnia", serif; font-size: 24px; font-weight: 400; padding-bottom: 4px; }
.about-community-content p a{color: #4E714A; text-decoration: underline;}
.about-community-img { max-width: 502px; width: 100%; margin: 0 0 0 auto; position: relative; padding-bottom: 71%; }
.about-community-img img { position: absolute; width: 100%; height: 100%; left: 0; right: 0; top: 0; bottom: 0; object-fit: cover; border-radius: 10px; }
.about-community-wrapper .row:nth-child(even) { flex-direction: row-reverse; }
.about-community-wrapper .row:nth-child(even) .about-community-img { margin: 0; }
.frequently-bg { background: #fff; padding: 42px 0; }
.frequently-wrapper { max-width: 626px; width: 100%; margin: 0 auto; }
.frequently-wrapper h4 { font-family: "Kalnia", serif; font-size: 24px; font-weight: 400; padding-bottom: 4px; }
.frequently-wrapper > p { line-height: 22px; padding-bottom: 38px; }
.frequently-wrapper .accordion-item { border: 1px solid #4E714A; background: #fff; border-radius: 10px; padding: 12px 15px; margin-bottom: 16.3px; }
.frequently-wrapper .accordion-item .accordion-header { width: 100%; padding: 0; height: auto; font-size: 0; }
.frequently-wrapper .accordion-item .accordion-button { position: relative; width: 100%; background: transparent; border: 0; color: #1D1D1D; font-size: 14px; font-weight: 400; line-height: 22px; text-align: left; font-family: "Poppins", sans-serif !important; }
.frequently-wrapper .accordion-item .accordion-button::after { content: ''; position: absolute; width: 13.47px; height: 13.47px; right: 0; top: 5px; background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.467' height='13.467' viewBox='0 0 13.467 13.467'%3E%3Cpath id='add_FILL0_wght400_GRAD0_opsz24' d='M205.771-752.3H200v-1.924h5.771V-760H207.7v5.772h5.772v1.924H207.7v5.771h-1.924Z' transform='translate(-200 760)' fill='%231d1d1d'/%3E%3C/svg%3E"); background-repeat: no-repeat; transition: transform .2s ease-in-out; background-position: center; }
.frequently-wrapper .accordion-item .accordion-button[aria-expanded="true"]::after { background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.465' height='1.924' viewBox='0 0 13.465 1.924'%3E%3Cpath id='remove_FILL1_wght400_GRAD0_opsz24' d='M200-518.076V-520h13.465v1.924Z' transform='translate(-200 520)' fill='%231d1d1d'/%3E%3C/svg%3E%0A"); }
.accordion-cont p { font-size: 14px; line-height: 22px;display: inline-block; }
.accordion-cont p span, .accordion-cont p a { color: #4E714A; text-decoration: underline; transition: all ease-in-out 0.3s; }
.accordion-cont p span:hover, .accordion-cont p a:hover { text-decoration: none; }
.collapse:not(.show) { display: none; }
.accordion-button { transition: all 0.3s ease-in-out; }
.accordion-cont{line-height: 10px;}
.btn-text-link{background: transparent;border: 0;padding: 0;color: #4E714A;}

@media (max-width:767px){
  
    
    .our_four_gird .nav-tabs {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        list-style: none;
        padding: 0 0 15px 80px;
        margin: 0; 
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .our_four_gird .nav-tabs::-webkit-scrollbar {
        display: none;
      } 
    
    .our_four_gird .nav-item {
        flex: 0 0 auto;
    }
    
    .our_four_gird .nav-link {
        display: inline-block; 
        text-decoration: none; 
        transition: background-color 0.3s, color 0.3s;
    }
     
    
    .our_four_gird .tab-content {
        padding: 15px; 
    }
    
    .our_four_gird .tab-pane {
        display: none;
    }
    
    .our_four_gird .tab-pane.active {
        display: block;
    }
    
    
    /* Ensure smooth scrolling on click */
    html {
        scroll-behavior: smooth;
    }

    .frequently-wrapper .accordion-item .accordion-button{padding-right: 18px;}
    .about-community-content h3{padding: 20px 0 10px;}
    .about-community-wrapper .row, .about-community-wrapper .row:nth-child(even){    flex-direction: column-reverse;}
}
