.services-main{padding: 45px 0 80px;}
.services-main .container{max-width: 1169px !important;}
.services-col{margin-bottom: 35px;}
.services-grid-cont > h3{font-size: 18px; line-height: 24px; font-weight: 400; color: #1D1D1D; padding: 10px 0 16px;}
.services-grid-cont > p{padding: 0 0 22px;}
.services-inner-card{background: #fff; border: solid 1px #97AD6E; border-radius: 10px; padding: 15px 15px 0 15px; position: relative; height: 100%; min-height: 396px;}
.services-inner-info{padding: 0 0 50px;}
.services-inner-card figure{position: relative;}
.services-inner-card figure img{width: 162px; max-width: 100%; height: 162px; display: block; object-fit: cover; border-radius: 5px;}
.services-inner-card h4{font-family: "Kalnia", serif; font-size: 16px; line-height: 22px; color: #282834; font-weight: 400; padding: 15px 0 7px;}
.services-inner-card p{font-size: 14px; line-height: 22px;}
.services-inner-main{padding: 70px 0 120px;}
.services-header{background: #EDF0EC; padding: 16px 0;position: sticky; position: -webkit-sticky; top: 116px; transition: all 0.5s ease-in-out; z-index: 1;}
.services-header ul{display: flex; justify-content: space-around; overflow-x: scroll;scroll-behavior: smooth; white-space: nowrap;position: relative;cursor: grab;}
.services-header ul::-webkit-scrollbar{width: 0; height: 0;}
.services-header ul li{padding: 0 10px;}
.services-header ul li a{font-size: 14px; line-height: 22px; font-weight: 400; color: #1D1D1D; cursor: pointer;user-select: none;}
.services-header ul li a.active{color: #4E714A; font-weight: 500; text-decoration: underline;cursor: grabbing;}
.services-header-inner{position: relative;max-width: 100%; width: 100%; margin: 0 auto; padding: 0 12px;}
.slide-button {position: absolute;width: 25px;border: none; background: #EDF0EC;height: 25px;display: none;align-items: center;justify-content: center;z-index: 1; border-radius: 50%;}
.slide-button img{width: 8px; height: auto;}
.slide-button.left {top: 50%; left: 0; transform: translateY(-50%);}
.slide-button.right { top: 50%; right: 0; transform: translateY(-50%) rotate(180deg);}

.services-inner-main .container{max-width: 1169px !important;}
.services-top-desc-cont > p{font-size: 18px; line-height: 24px; color: #595A5C;}
.serv-top-desc-inner p{font-size: 16px; line-height: 22px; color: #595A5C; padding: 10px 0 5px;}
.serv-top-desc-inner p span{color: #1D1D1D;}
.serv-top-desc-inner p a{color: #4E714A; text-decoration: underline;}
.services-list-grid-cont{padding: 25px 0 0;}
.salon-wellenss-card{border: solid 1px #97AD6E; background: #fff; padding: 20px 15px 35px 15px; border-radius: 10px; margin: 0 0 16px;}
.salon-wellness-top h3{font-family: "Kalnia", serif; font-size: 16px; line-height: 22px; color: #282834; font-weight: 400;}
.salon-wellness-top p, .salon-prices-card p{font-size: 14px; line-height: 22px; color: #595A5C; padding: 0 0 20px;}
.salon-wellness-top p a, .salon-wellness-top p span, .salon-prices-card p a {color: #4E714A; text-decoration: underline !important;}
.salon-wellness-top p a:hover, .salon-wellness-top p span:hover, .salon-prices-card p a:hover { text-decoration: none !important;}
.salon-wellness-top p:last-child{padding: 0;}
.salon-prices-card{padding: 15px 0 0;}
.salon-prices-card p{padding: 0;}
.salon-price-flex{display: flex;}
.salon-price-flex .salon-service, .salon-price-flex .salon-price{font-size: 14px; line-height: 24px; color: #1D1D1D; flex: 1;}
.salon-price-flex .salon-price{flex: 0 0 auto; font-weight: 500;}
.salon-before-appt-cont{background: #ECF0EC; padding: 25px 35px; min-height: 328px; display: flex; align-items: flex-start; flex-flow: column; justify-content: center; border-radius: 10px;}
.salon-before-appt-cont .heading_24{font-size: 20px; padding: 15px 0;}
.salon-before-appt-cont figure{margin: 0;}
.salon-before-appt-cont figure svg{max-width: 100%; height: auto;}
.salon-before-appt-cont p{padding: 0 0 15px; font-size: 14px; line-height: 21px; color: #1D1D1D;}
.salon-before-appt-cont .btn-primary{line-height: 23px;padding: 12px 8px; margin: 10px 0 0; width: 147px; max-width: 100%;}
.cutstyle-modal .check-your-inbox-gird{max-width: 422px;min-height: 482px; text-align: left;align-items: flex-start;}
.cutstyle-modal h3{font-family: "Kalnia", serif; font-size: 16px; line-height: 22px; color: #282834; font-weight: 400;padding: 35px 0 0;}
.cutstyle-modal .check-your-inbox-gird figure img{ width: 100%; height: auto; border-radius: 5px;}
.scroll-position-wellness {position: relative; top: -120px;}


.services-top-desc-cont>p , .services-top-desc-cont span, .services-top-desc-cont p {
    color: #595a5c  !important;
    font-size: 18px !important;
    line-height: 28px !important;
    font-weight: 400 !important;
    font-family: "Poppins", sans-serif !important;
}

.services-top-desc-cont a {color: #4E714A !important;text-decoration: underline !important;}
.services-top-desc-cont .font-heading{color: #1D1D1D !important;font-size: 16px !important;}
.services-top-desc-cont .font-16{font-size: 16px !important;}

.list-nav li{padding-bottom: 10px;}
.list-nav li a{
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #1D1D1D;
}

.page-heading{
    font-size: 18px;
    line-height: 24px;
    color: #92949A;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    font-weight: 400;margin-bottom: 16px;
}
ul.length-class{justify-content: flex-end;}
ul.length-class li {padding: 0 16px;}

@media (max-width:1079px){
.services-header{top: 100px;}
}
@media (max-width:767px){
.services-main{padding: 35px 0 50px;}
.services-col{margin-bottom: 22px;}
.services-top-desc-cont > p{font-size: 17px; color: #000;}
.serv-top-desc-inner p span{font-size: 18px;}
.services-header ul{justify-content: flex-start;white-space: nowrap;overflow: auto;}
.services-header ul::-webkit-scrollbar {width: 5px; height: 5px; position: relative; transform: translateX(10px); }
.services-header ul::-webkit-scrollbar-track { box-shadow: none; border-radius: 3px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 3px; -o-border-radius: 5px; background-color: #F0F1F4; }
.services-header ul::-webkit-scrollbar-thumb { background: #B7BCC6; border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; }
.services-header ul li{padding: 0 15px 0 0;}
.services-inner-main{padding: 35px 0 70px;}


}
@media (max-width:575px){
.services-inner-card figure img{width: 100%; max-height: 330px; height: auto;}
.salon-before-appt-cont{margin: 35px 0 0;padding: 25px 25px;}
.cutstyle-modal h3{font-size: 24px; line-height: 36px;padding: 20px 0 0;}
.cutstyle-modal .check-your-inbox-gird{min-height: 482px;}
.cutstyle-modal .check-your-inbox-gird p{font-size: 16px;}
.cutstyle-modal .check-your-inbox-gird p br{display: none;}

}
